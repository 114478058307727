



































import { defineComponent } from "@vue/composition-api";
import BaseCard from "@/components/core/BaseCard.vue";
import ExpandableImage from "@/components/core/ExpandableImage.vue";

export default defineComponent({
  name: "ExpandableCard",
  components: { ExpandableImage, BaseCard },
  props: {
    thumbnailUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
});
