












import { defineComponent } from "@vue/composition-api";
import AboutMe from "@/views/landing/sections/AboutMe.vue";
import Home from "@/views/landing/sections/Home.vue";
import Education from "@/views/landing/sections/Education.vue";
import Experience from "@/views/landing/sections/Experience.vue";
import Portfolio from "@/views/landing/sections/Portfolio.vue";
import Posts from "@/views/landing/sections/Posts.vue";
import Bloopers from "@/views/landing/sections/Bloopers.vue";
import { user } from "@/data/user";

export default defineComponent({
  name: "Landing",
  metaInfo: {
    title: user.fullName,
  },
  components: {
    Home,
    AboutMe,
    Education,
    Experience,
    Portfolio,
    Posts,
    Bloopers,
  },
});
