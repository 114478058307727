


































import { defineComponent } from "@vue/composition-api";
import buildUrl from "cloudinary-build-url";
import BaseImage from "@/components/core/BaseImage.vue";

export default defineComponent({
  name: "ExpandableImage",
  components: { BaseImage },
  props: {
    title: {
      type: String,
      required: true,
    },
    imageId: {
      type: String,
      required: true,
    },
    imageClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    thumbnailUrl(): string {
      return buildUrl(this.imageId, {
        transformations: {
          resize: {
            height: 320,
            type: "thumb",
          },
        },
      });
    },
    imageUrl(): string {
      return buildUrl(this.imageId, {
        transformations: {
          resize: {
            height: 1280,
          },
        },
      });
    },
  },
});
