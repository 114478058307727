
















import { defineComponent } from "@vue/composition-api";
import { user } from "@/data/user";

export default defineComponent({
  name: "SocialLinks",
  data() {
    return {
      user,
    };
  },
});
