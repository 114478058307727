











import { defineComponent } from "@vue/composition-api";
import { PartialRecord } from "@/types/generics";
import { BreakpointName } from "vuetify/types/services/breakpoint";

export default defineComponent({
  name: "CardListTransition",
  computed: {
    threshold() {
      const defaultThreshold = 0.3;
      const thresholds: PartialRecord<BreakpointName, number> = {
        xs: 0.1,
        sm: 0.1,
      };
      return thresholds[this.$vuetify.breakpoint.name] || defaultThreshold;
    },
  },
});
