var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('xyz-transition',{attrs:{"appear-visible":"","xyz":"fade"}},[_c('v-container',{attrs:{"fluid":""}},[_c('section-title',[_vm._v("Experience")]),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"10"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","md":"6"}},[_c('div',[_c('h1',{staticClass:"pt-5 text-h5 primary--text text-uppercase"},[_vm._v(" "+_vm._s(_vm.experienceSummary.subtitle)+" ")]),_c('h2',{staticClass:"py-3 text-h4"},[_vm._v("I'm specialized in web technologies")]),_c('p',{staticClass:"py-2 subtitle-1 mb-0"},[_vm._v(" "+_vm._s(_vm.experienceSummary.description)+" ")]),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                handler: _vm.setIntersected,
                options: { threshold: [0.05] },
              }),expression:"{\n                handler: setIntersected,\n                options: { threshold: [0.05] },\n              }"}]},_vm._l((_vm.areasItems),function(areaItem){return _c('div',{key:areaItem.area.name,staticClass:"py-3"},[_c('div',{class:("subtitle-1 font-weight-bold " + (areaItem.area.color) + "--text")},[_vm._v(" "+_vm._s(areaItem.area.name)+" ")]),_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(areaItem.description)+" ")]),_c('div',{staticClass:"d-flex"},_vm._l((areaItem.technologies),function(technology){return _c('div',{key:((areaItem.name) + "-" + (technology.name)),staticClass:"pr-5 d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"text--secondary py-1 font-weight-bold"},[_vm._v(" "+_vm._s(technology.name)+" ")]),_c('v-progress-circular',{attrs:{"aria-label":((areaItem.area.name) + " experience level"),"width":"5","color":areaItem.area.color,"rotate":-90,"size":42,"value":_vm.isIntersecting ? technology.progress : 0}},[_vm._v(" "+_vm._s(technology.progress)+" ")])],1)}),0),_c('v-divider',{staticClass:"mt-7"})],1)}),0)])]),_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","md":"6"}},[_c('card-list-transition',_vm._l((_vm.experienceItems),function(ref){
              var organization = ref.organization;
              var period = ref.period;
              var description = ref.description;
              var photos = ref.photos;
return _c('base-card',{key:organization,staticClass:"mb-2 hoverable-card"},[_c('v-card-title',[_vm._v(" "+_vm._s(organization)+" ")]),_c('v-card-subtitle',[_vm._v(_vm._s(period))]),_c('v-card-text',[_vm._v(" "+_vm._s(description)+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"mr-1"},_vm._l((photos),function(ref){
              var imageId = ref.imageId;
              var title = ref.title;
return _c('v-col',{key:title,staticClass:"pr-0",attrs:{"cols":12 / photos.length}},[_c('expandable-image',{staticClass:"grey darken-4 rounded",attrs:{"title":title,"image-id":imageId,"height":"150"}})],1)}),1)],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }