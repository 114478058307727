var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"main-drawer",attrs:{"app":"","temporary":"","value":_vm.isExpanded},on:{"input":_vm.setDrawer},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('social-links',{staticClass:"d-flex justify-center my-4"})]},proxy:true}])},[_c('div',{staticClass:"avatar-container"},[_c('avatar',{attrs:{"image-url":_vm.user.photoUrl,"subtitle":_vm.user.fullName}},[_c('h4',{staticClass:"pt-2 text-h6 font-weight-bold"},[_vm._v(_vm._s(_vm.user.fullName))]),_c('h3',{staticClass:"subtitle-1 text--secondary"},[_vm._v(_vm._s(_vm.user.profession))])])],1),_c('scrollactive',{ref:"scrollActive",attrs:{"active-class":"v-list-item--active","offset":0,"modify-url":false,"highlight-first-item":""},on:{"itemchanged":_vm.onItemActive}},[_c('v-list',{staticClass:"px-4"},_vm._l((_vm.links),function(ref,index){
var icon = ref.icon;
var to = ref.to;
var title = ref.title;
return _c('v-list-item',{key:title,staticClass:"scrollactive-item",class:{
          'v-list-item--active': _vm.$route.hash
            ? _vm.$route.hash === to
            : index === 0,
        },attrs:{"data-destination-hash":to,"href":to,"aria-label":title,"dense":""}},[_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }