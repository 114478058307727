










import { defineComponent } from "@vue/composition-api";
import Drawer from "@/components/app/Drawer.vue";
import AppBar from "@/components/app/AppBar.vue";

export default defineComponent({
  name: "App",
  components: {
    AppBar,
    Drawer,
  },
  data: () => ({}),
});
