var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"about_me py-5",attrs:{"fluid":""}},[_c('section-title',[_vm._v("About me")]),_c('xyz-transition',{attrs:{"appear-visible":"","duration":"auto","xyz":"fade stagger-4"}},[_c('div',[_c('v-row',{class:{
          'my-5': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('v-col',{staticClass:"d-flex justify-center xyz-nested",attrs:{"cols":"10","sm":"6","offset":"1","md":"4","lg":"3","xl":"2","offset-sm":"3","offset-lg":"3","offset-md":"1","offset-xl":"3"}},[_c('v-img',{staticClass:"rounded-xl",attrs:{"src":_vm.imageUrl,"min-height":"500"}})],1),_c('v-col',{staticClass:"d-flex align-center xyz-nested",attrs:{"cols":"12","sm":"10","offset-sm":"1","md":"6","lg":"4","offset-md":"0"}},[_c('v-card',{staticClass:"elevation-0 transparent",attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"text-h6 py-2"},[_vm._v(" "+_vm._s(_vm.profileSummary.tagline)+" ")]),_c('v-card-text',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.profileSummary.description)+" ")]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.profileItems),function(item){return _c('tr',{key:item.name},[_c('th',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.value))])])}),0)]},proxy:true}])})],1),_c('v-card-text',{staticClass:"my-4"},[_c('v-btn',{staticClass:"px-5",attrs:{"rounded":"","color":"primary","link":"","target":"_blank","rel":"noreferrer","href":_vm.cvUrl,"aria-label":"Resume"}},[_vm._v(" Resume ")])],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-col',{class:{
            'my-10': _vm.$vuetify.breakpoint.mdAndUp,
          },attrs:{"cols":"12","sm":"10","md":"8","lg":"12","xl":"10","offset-lg":"0","offset-sm":"1","offset-md":"2","offset-xl":"1","xyz":"delay-7 fade down stagger"}},[_c('v-row',{staticClass:"mx-auto"},_vm._l((_vm.userStrengths),function(ref){
          var icon = ref.icon;
          var title = ref.title;
          var description = ref.description;
          var color = ref.color;
return _c('v-col',{key:title,staticClass:"xyz-nested",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-card',{staticClass:"full-height hoverable-card"},[_c('v-card-text',{staticClass:"d-flex flex-column justify-center align-center",class:{
                    'pa-0': _vm.$vuetify.breakpoint.xsOnly,
                  }},[_c('v-icon',{staticClass:"my-5",attrs:{"color":color,"x-large":""}},[_vm._v(" "+_vm._s(icon)+" ")]),_c('v-card-title',{staticClass:"py-0"},[_vm._v(_vm._s(title))]),_c('v-card-text',{staticClass:"text-center"},[_vm._v(" "+_vm._s(description)+" ")])],1)],1)],1)}),1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }