






























































import { defineComponent } from "@vue/composition-api";
import SectionTitle from "@/components/core/SectionTitle.vue";
import ExpandableImage from "@/components/core/ExpandableImage.vue";
import { educationItems } from "@/data/education";
import BaseCard from "@/components/core/BaseCard.vue";

export default defineComponent({
  name: "Education",
  components: { BaseCard, ExpandableImage, SectionTitle },
  data() {
    return {
      educationItems,
    };
  },
  computed: {
    maxVisiblePhotos() {
      return this.$vuetify.breakpoint.smAndDown ? 2 : 3;
    },
  },
});
