






































































































import { defineComponent } from "@vue/composition-api";
import SectionTitle from "@/components/core/SectionTitle.vue";
import ExpandableImage from "@/components/core/ExpandableImage.vue";
import CardListTransition from "@/components/transitions/CardListTrasition.vue";
import {
  areasItems,
  experienceItems,
  experienceSummary,
} from "@/data/experience";
import BaseCard from "@/components/core/BaseCard.vue";

export default defineComponent({
  name: "Experience",
  components: {
    BaseCard,
    CardListTransition,
    ExpandableImage,
    SectionTitle,
  },
  data() {
    return {
      isIntersecting: false,
      areasItems,
      experienceSummary,
      experienceItems,
    };
  },
  methods: {
    setIntersected(entries: IntersectionObserverEntry[]) {
      const { isIntersecting } = entries[0];
      setTimeout(() => (this.isIntersecting = isIntersecting), 400);
    },
  },
});
