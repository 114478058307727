





import VueTyped from "vue-typed-js";
import "vue-typed-js/dist/vue-typed-js.css";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TypingText",
  components: {
    VueTyped,
  },
  data() {
    return {};
  },
});
