






























































import { defineComponent } from "@vue/composition-api";
import SectionTitle from "@/components/core/SectionTitle.vue";
import BaseImage from "@/components/core/BaseImage.vue";
import { photos } from "@/data/profile";
import { PartialRecord } from "@/types/generics";
import { BreakpointName } from "vuetify/types/services/breakpoint";

export default defineComponent({
  components: {
    BaseImage,
    SectionTitle,
  },
  name: "Bloopers",
  data() {
    return {
      activePhotoIndex: 0,
      photos,
    };
  },
  computed: {
    carouselHeight(): number {
      const defaultHeight = 500;
      const heights: PartialRecord<BreakpointName, number> = {
        md: 350,
        xs: 250,
        sm: 300,
      };
      return heights[this.$vuetify.breakpoint.name] || defaultHeight;
    },
  },
});
