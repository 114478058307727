var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',{staticClass:"col-xl-6 mx-auto pb-10"},[_c('section-title',[_vm._v("Bloopers")]),_c('p',{staticClass:"text-h6"},[_vm._v(" Who said only movies and TV shows are allowed to have them? Here we have some pictures of my beautiful journey building this landing page ")]),_c('v-sheet',{staticClass:"pa-4 rounded-lg background darken-5"},[_c('v-slide-group',{attrs:{"mandatory":"","show-arrows":""},model:{value:(_vm.activePhotoIndex),callback:function ($$v) {_vm.activePhotoIndex=$$v},expression:"activePhotoIndex"}},_vm._l((_vm.photos),function(photo){return _c('v-slide-item',{key:("" + (photo.title)),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-img',{staticClass:"ma-2 slider-image rounded-lg cursor-pointer",class:{
              'active-image': active,
            },attrs:{"src":photo.thumbnailUrl,"aria-label":((photo.title) + " thumbnail"),"height":"100","width":"150"},on:{"click":toggle}})]}}],null,true)})}),1),_c('v-expand-transition',[(_vm.activePhotoIndex != null)?_c('v-sheet',{staticClass:"mx-2 mt-2 transparent"},[_c('v-carousel',{staticClass:"position-relative",attrs:{"continuous":false,"delimiter-icon":"$mdi-minus","height":_vm.carouselHeight,"hide-delimiters":""},model:{value:(_vm.activePhotoIndex),callback:function ($$v) {_vm.activePhotoIndex=$$v},expression:"activePhotoIndex"}},_vm._l((_vm.photos),function(ref,index){
            var imageUrl = ref.imageUrl;
            var title = ref.title;
return _c('v-carousel-item',{key:(title + "-carousel-photo-" + index)},[_c('base-image',{staticClass:"full-height rounded-lg",attrs:{"src":imageUrl,"aria-label":title}})],1)}),1),_c('p',{staticClass:"subtitle-1 ma-0 pt-2"},[_vm._v(" "+_vm._s(_vm.photos[_vm.activePhotoIndex].title)+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }