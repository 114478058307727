


















import { defineComponent } from "@vue/composition-api";
import ExpandableCard from "@/components/core/ExpandableCard.vue";
import ProjectDetail from "@/components/portfolio/ProjectDetail.vue";
import CardListTransition from "@/components/transitions/CardListTrasition.vue";
import { projects } from "@/data/portfolio";

export default defineComponent({
  name: "Portfolio",
  components: {
    CardListTransition,
    ProjectDetail,
    ExpandableCard,
  },
  data() {
    return {
      projects,
    };
  },
});
