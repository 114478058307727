




















































import { defineComponent } from "@vue/composition-api";
import { mapMutations, mapState } from "vuex";
import Avatar from "@/components/core/Avatar.vue";
import { user } from "@/data/user";
import SocialLinks from "@/components/user/SocialLinks.vue";

export default defineComponent({
  name: "Drawer",
  components: { Avatar, SocialLinks },
  data() {
    return {
      user,
      links: [
        {
          icon: "$mdi-home",
          to: "#home",
          title: "Home",
        },
        {
          icon: "$mdi-language-javascript",
          to: "#about_me",
          title: "About me",
        },
        {
          icon: "$mdi-school",
          to: "#education",
          title: "Education",
        },
        {
          icon: "$mdi-briefcase-variant",
          to: "#experience",
          title: "Experience",
        },
        {
          icon: "$mdi-view-carousel",
          to: "#portfolio",
          title: "Portfolio",
        },
        {
          icon: "$mdi-view-split-vertical",
          to: "#posts",
          title: "Posts",
        },
        {
          icon: "$mdi-dog",
          to: "#bloopers",
          title: "Bloopers",
        },
      ] as { icon: string; to: string; title: string }[],
    };
  },
  computed: {
    ...mapState("app", ["isExpanded"]),
    drawer() {
      return this.isExpanded || this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    ...mapMutations("app", ["TOGGLE_DRAWER"]),
    setDrawer(isExpanded: boolean) {
      if (this.isExpanded === isExpanded) return;
      this.TOGGLE_DRAWER();
    },
    async goToHash(hash: string) {
      if (this.$route.hash === hash) return;
      await this.$router.replace({
        path: hash,
        hash,
      });
    },
    async onItemActive(event: any, currentItem: HTMLElement): Promise<void> {
      if (!currentItem.dataset.destinationHash) return;
      await this.goToHash(currentItem.dataset.destinationHash);
    },
  },
});
