























































































































import { defineComponent } from "@vue/composition-api";
import BaseCard from "@/components/core/BaseCard.vue";
import SectionTitle from "@/components/core/SectionTitle.vue";
import { user } from "@/data/user";
import { profileItems, profileSummary, userStrengths } from "@/data/profile";
import { buildResizedImageUrl } from "@/utils/image";

export default defineComponent({
  name: "AboutMe",
  components: { BaseCard, SectionTitle },
  data() {
    return {
      profileSummary,
      userStrengths,
      profileItems,
      imageUrl: buildResizedImageUrl("landing/profile/about_me", {
        height: 600,
      }),
      cvUrl: user.cvUrl,
    };
  },
});
