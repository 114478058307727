






























import { defineComponent } from "@vue/composition-api";
import ExpandableCard from "@/components/core/ExpandableCard.vue";
import CardListTransition from "@/components/transitions/CardListTrasition.vue";
import { posts } from "@/data/posts";

export default defineComponent({
  name: "Posts",
  components: {
    ExpandableCard,
    CardListTransition,
  },
  data() {
    return {
      posts,
    };
  },
});
