import {
  mdiBookshelf,
  mdiBriefcaseVariant,
  mdiClose,
  mdiCodeBraces,
  mdiDog,
  mdiFunctionVariant,
  mdiGithub,
  mdiGitlab,
  mdiHeart,
  mdiHome,
  mdiLanguageJavascript,
  mdiLinkedin,
  mdiSchool,
  mdiSpeedometer,
  mdiViewCarousel,
  mdiViewSplitVertical,
} from "@mdi/js";

export const ICONS = {
  "mdi-home": mdiHome,
  "mdi-language-javascript": mdiLanguageJavascript,
  "mdi-school": mdiSchool,
  "mdi-briefcase-variant": mdiBriefcaseVariant,
  "mdi-view-carousel": mdiViewCarousel,
  "mdi-view-split-vertical": mdiViewSplitVertical,
  "mdi-github": mdiGithub,
  "mdi-linkedin": mdiLinkedin,
  "mdi-gitlab": mdiGitlab,
  "mdi-function-variant": mdiFunctionVariant,
  "mdi-code-braces": mdiCodeBraces,
  "mdi-bookshelf": mdiBookshelf,
  "mdi-speedometer": mdiSpeedometer,
  "mdi-close": mdiClose,
  "mdi-heart": mdiHeart,
  "mdi-dog": mdiDog,
};
