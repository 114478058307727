var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-theme-provider',{attrs:{"dark":""}},[_c('v-container',{directives:[{name:"intersect",rawName:"v-intersect",value:({
      handler: _vm.setHomeVisibility,
      options: {
        threshold: [0.2],
      },
    }),expression:"{\n      handler: setHomeVisibility,\n      options: {\n        threshold: [0.2],\n      },\n    }"}],staticClass:"px-5 white--text align-center full-height-viewport position-relative background darken-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"full-height"},[_c('xyz-transition',{attrs:{"appear":"","duration":"auto","xyz":"fade"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center",staticStyle:{"z-index":"1"},attrs:{"cols":"10","sm":"6","lg":"5","xl":"4","offset-lg":"1","offset":"1"}},[_c('span',{staticClass:"text-h6 py-2"},[_vm._v(" Hey there! ")]),_c('h2',{staticClass:"font-weight-bold",class:{
              'text-h2': _vm.$vuetify.breakpoint.lgAndUp,
              'text-h3': _vm.$vuetify.breakpoint.mdOnly,
              'text-h4': _vm.$vuetify.breakpoint.smAndDown,
            }},[_vm._v(" I'm "),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.user.fullName)+" ")])]),_c('p',{staticClass:"py-4 mb-0",class:{
              'text-h6': _vm.$vuetify.breakpoint.mdAndUp,
              'subtitle-1': _vm.$vuetify.breakpoint.smAndDown,
            }},[_vm._v(" I specialize in building web applications. I'm extremely passionate about creating maintainable and efficient software ")]),_c('vue-typed-js',{staticClass:"d-inline-block",attrs:{"strings":[
              'Software Engineer',
              'Fullstack Developer',
              'Problem Solver' ],"loop":"","type-speed":50,"back-speed":30}},[_c('h1',{class:{
                'text-h4': _vm.$vuetify.breakpoint.lgAndUp,
                'text-h5': _vm.$vuetify.breakpoint.mdAndDown,
              }},[_c('span',{staticClass:"font-weight-bold typing"})])]),_c('div',{staticClass:"mt-4"},[_c('social-links')],1)],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-img',{staticClass:"position-absolute hero-img",staticStyle:{"bottom":"0","right":"0"},attrs:{"src":_vm.homeImage,"height":"100vh"}}):_vm._e(),_c('div',{staticClass:"hero-filter"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }