














































































































import { defineComponent, PropType } from "@vue/composition-api";
import ExpandableCard from "@/components/core/ExpandableCard.vue";
import { BreakpointName } from "vuetify/types/services/breakpoint";
import { PartialRecord } from "@/types/generics";
import { ProfessionAreaItem } from "@/types/experience";
import { DateRangeData } from "@/types/portfolio";
import { PhotoDetail } from "@/types/user";
import BaseImage from "@/components/core/BaseImage.vue";
import BaseCard from "@/components/core/BaseCard.vue";

export default defineComponent({
  name: "ProjectDetail",
  components: {
    BaseCard,
    BaseImage,
    ExpandableCard,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    additionalDescription: {
      type: String,
      required: true,
    },
    url: {
      type: String,
    },
    timeRange: {
      type: Object as PropType<DateRangeData>,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
    photos: {
      type: Array as PropType<PhotoDetail[]>,
      required: true,
    },
    areaItems: {
      type: Array as PropType<ProfessionAreaItem[]>,
      required: true,
    },
    mainTechnologies: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      activeSlideIndex: 0,
      isOpen: false,
    };
  },
  computed: {
    carouselHeight(): number {
      const defaultHeight = 500;
      const heights: PartialRecord<BreakpointName, number> = {
        md: 400,
        xs: 200,
        sm: 300,
      };
      return heights[this.$vuetify.breakpoint.name] || defaultHeight;
    },
    durationMilliseconds(): number | null {
      if (!this.timeRange) return null;
      const endDate = this.timeRange.endDate
        ? new Date(this.timeRange.endDate)
        : new Date();
      const startDate = new Date(this.timeRange.startDate);
      return endDate.getTime() - startDate.getTime();
    },
  },
});
