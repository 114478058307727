




















































































import { defineComponent } from "@vue/composition-api";
import { user } from "@/data/user";
import TypingText from "@/components/core/TypingText.vue";
import { useNamespacedMutations } from "vuex-composition-helpers";
import buildUrl from "cloudinary-build-url";
import SocialLinks from "@/components/user/SocialLinks.vue";

export default defineComponent({
  name: "Home",
  components: {
    SocialLinks,
    TypingText,
  },
  setup() {
    return {
      ...useNamespacedMutations("app", ["SET_HOME_VISIBILITY"]),
    };
  },
  data: () => ({
    fab: false,
    user,
    homeImage: buildUrl("landing/profile/hero_ueddah", {
      transformations: {
        resize: {
          width: 1920,
        },
      },
    }),
  }),
  methods: {
    setHomeVisibility(entries: IntersectionObserverEntry[]) {
      const { isIntersecting } = entries[0];
      this.SET_HOME_VISIBILITY(isIntersecting);
    },
  },
});
