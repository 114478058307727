var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('xyz-transition',{attrs:{"appear-visible":"","xyz":"fade"}},[_c('v-container',{attrs:{"fluid":""}},[_c('section-title',[_vm._v("Education")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-timeline',{attrs:{"dense":_vm.$vuetify.breakpoint.mdAndDown}},[_c('xyz-transition-group',{attrs:{"xyz":"fade down stagger","duration":"3","appear-visible":""}},_vm._l((_vm.educationItems),function(ref){
              var institution = ref.institution;
              var description = ref.description;
              var period = ref.period;
              var logoUrl = ref.logoUrl;
              var photos = ref.photos;
return _c('v-timeline-item',{key:institution,scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',{attrs:{"size":"32","color":"gray"}},[_c('v-img',{attrs:{"src":logoUrl}})],1)]},proxy:true}],null,true)},[_c('base-card',[_c('div',{staticClass:"hoverable-card"},[_c('v-card-title',[_vm._v(" "+_vm._s(institution)+" ")]),_c('v-card-subtitle',[_vm._v(_vm._s(period))]),_c('v-card-text',[_vm._v(" "+_vm._s(description)+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"mr-1"},_vm._l((photos),function(ref,index){
              var imageId = ref.imageId;
              var title = ref.title;
return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.maxVisiblePhotos),expression:"index < maxVisiblePhotos"}],key:title,staticClass:"pr-0",attrs:{"cols":12 / Math.min(_vm.maxVisiblePhotos, photos.length)}},[_c('expandable-image',{staticClass:"grey darken-4 rounded",attrs:{"title":title,"image-id":imageId,"height":"150"}})],1)}),1)],1)],1)])],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }