
















import { defineComponent } from "@vue/composition-api";
import { mapMutations } from "vuex";
import { useNamespacedState } from "vuex-composition-helpers";

export default defineComponent({
  name: "AppBar",
  data() {
    return {
      isVisible: false,
    };
  },
  setup() {
    return {
      ...useNamespacedState("app", ["isHomeVisible"]),
    };
  },
  methods: {
    ...mapMutations("app", ["TOGGLE_DRAWER"]),
  },
  computed: {
    background(): string {
      return !this.$vuetify.breakpoint.mobile || this.isHomeVisible
        ? "transparent"
        : "";
    },
  },
});
